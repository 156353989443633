<template>
  <div>
    <!-- <div class="start start--new" v-if="!nextScreen">
      <div class="start__line"></div>
      <div class="start__group">
        <div class="start__group-element start__group-element-1"></div>
        <div class="start__group-element start__group-element-2"></div>
      </div>
      <div class="start__element start__element-1"></div>
      <div class="start__element start__element-2"></div>
      <div class="start-bg start-bg-1">
        <div class="start__year">1930</div>
      </div>
      <div class="start-bg start-bg-2">
        <div class="start__year start__year-2" v-if="isShowSecondDate">2024</div>
      </div>
      <div class="start__title">
        <span>День</span>
        <br /><span class="title-2">СВЯЗИ</span>
      </div>
      <div class="start__button">
        <a href="javascript:void(0)" class="start__button-link" @click="start">Далее</a>
      </div>
      <div class="start__overlay"></div>
    </div> -->
    <!-- <div class="start start--info" v-if="nextScreen">
      <div class="start__box">
        <div class="start__logo">
          <img src="@/assets/img/logo-5.svg" alt="" />
        </div>
        <div class="start__box-block">
          <div class="start__box-title">
            <span>25 сентября <br />«День компании Ростелеком»!</span>
          </div>
          <div class="start__box-text">
            Чтобы начать, выберите одну <br class="mob-only" />из
            <span>новых активностей</span> ниже <br />или <br class="mob-only" />стартуйте
            с <span>Начала Времен</span>
          </div>
          <div class="start__box-items" @click="go('generation')">
            <div class="start__box-item">
              <div class="start__box-item-icon start__box-item-icon--1"></div>
              <div class="start__box-item-title">Связь поколений</div>
            </div>
            <div class="start__box-item" @click="go('history')">
              <div class="start__box-item-icon start__box-item-icon--2"></div>
              <div class="start__box-item-title">Истории связи</div>
            </div>
            <div class="start__box-item" @click="go('technology')">
              <div class="start__box-item-icon start__box-item-icon--3"></div>
              <div class="start__box-item-title">Сила технологий</div>
            </div>
            <div class="start__box-item" @click="go('default')">
              <div class="start__box-item-icon start__box-item-icon--4"></div>
              <div class="start__box-item-title">Начало времён</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="start start--ny">
      <div class="start__buildings all-activities" v-if="type == 1">
        <div class="start__title"></div>
        <div class="start__shadow start__shadow-1"></div>
        <div class="start__shadow start__shadow-2"></div>
        <div class="start__shadow start__shadow-3"></div>
        <div class="start__bridge"></div>
        <div class="start__building start__building-1">
          <div
            class="start-ny__button start-ny__button--1"
            @click="
              go('ether');
              sendEvent('Live broadcas');
            "
            :class="{ visible: elements.includes(1) }"
          >
            <div class="start-ny__button-title">Прямой <br />эфир</div>
            <div class="start-ny__button-icon"></div>
          </div>
        </div>
        <div class="start__building start__building-2">
          <div
            class="start-ny__button start-ny__button--2"
            @click="
              go('generation');
              sendEvent(`Connection of generations`);
            "
            :class="{ visible: elements.includes(2) }"
          >
            <div class="start-ny__button-title">Связь <br />поколений</div>
            <div class="start-ny__button-icon"></div>
          </div>
        </div>
        <div class="start__building start__building-3">
          <a
            href="https://t.me/rtk_ny_cards_bot"
            target="_blank"
            class="start-ny__button start-ny__button--3"
            :class="{ visible: elements.includes(3) }"
          >
            <div class="start-ny__button-title">Умная <br />открытка</div>
            <div class="start-ny__button-icon"></div>
          </a>
        </div>
        <div class="start__building start__building-4">
          <a
            href="https://anketolog.ru/s/888204/Kmibwzsa"
            target="_blank"
            class="start-ny__button start-ny__button--4"
            :class="{ visible: elements.includes(4) }"
            @click="sendEvent(`secrets`)"
          >
            <div class="start-ny__button-title">Секреты <br />праздника</div>
            <div class="start-ny__button-icon"></div>
          </a>
        </div>
        <div class="start__building start__building-5">
          <a
            href="https://kids.rtk.events"
            target="_blank"
            class="start-ny__button start-ny__button--5"
            :class="{ visible: elements.includes(5) }"
            @click="sendEvent(`Wonderful calendar`)"
          >
            <div class="start-ny__button-title">Чудесный <br />календарь</div>
            <div class="start-ny__button-icon"></div>
          </a>
        </div>
        <div class="start__building start__building-6">
          <a
            href="https://dream.rt.ru/"
            target="_blank"
            class="start-ny__button start-ny__button--6"
            :class="{ visible: elements.includes(6) }"
            @click="sendEvent('Time for miracles')"
          >
            <div class="start-ny__button-title">Время чудес!</div>
            <div class="start-ny__button-subtitle">
              Открывайте из <br />внутренней сети
            </div>
            <div class="start-ny__button-icon"></div>
          </a>
        </div>
        <div class="start__building start__building-7">
          <a
            href="https://ankt.cc/sHXqMZ"
            target="_blank"
            class="start-ny__button start-ny__button--7"
            :class="{ visible: elements.includes(7) }"
            @click="sendEvent(`New Year's Quiz`)"
          >
            <div class="start-ny__button-title">Новогодний <br />квиз</div>
            <div class="start-ny__button-icon"></div>
          </a>
        </div>
        <div class="start__building start__building-8">
          <a
            href="https://afisha-university.rt.ru/2025?utm_source=rtk_miracles&utm_medium=anons&utm_campaign=new_year"
            target="_blank"
            class="start-ny__button start-ny__button--8"
            :class="{ visible: elements.includes(8) }"
          >
            <div class="start-ny__button-title">Я — Волшебник</div>
            <div class="start-ny__button-icon"></div>
          </a>
        </div>
        <div class="start__building start__building-9">
          <div
            class="start-ny__button start-ny__button--9"
            :class="{ visible: elements.includes(9) }"
            @click="go('game')"
          >
            <div class="start-ny__button-title">Госуслуги <br />рядом</div>
            <div class="start-ny__button-icon"></div>
          </div>
        </div>
      </div>
      <div class="start__buildings for-visitors" v-if="type == 2">
        <div class="start__title"></div>
        <div class="start__shadow start__shadow-1"></div>
        <div class="start__shadow start__shadow-2"></div>
        <div class="start__shadow start__shadow-3"></div>
        <div class="start__bridge"></div>
        <div class="start__building start__building-1"></div>
        <div class="start__building start__building-2"></div>
        <div class="start__building start__building-3">
          <div
            class="start-ny__button start-ny__button--2"
            @click="
              go('generation');
              sendEvent(`Connection of generations`);
            "
            :class="{ visible: elements.includes(1) }"
          >
            <div class="start-ny__button-title">Связь <br />поколений</div>
            <div class="start-ny__button-icon"></div>
          </div>
        </div>
        <div class="start__building start__building-4">
          <a
            href="https://t.me/rtk_ny_cards_bot"
            target="_blank"
            class="start-ny__button start-ny__button--3"
            :class="{ visible: elements.includes(2) }"
          >
            <div class="start-ny__button-title">Умная <br />открытка</div>
            <div class="start-ny__button-icon"></div>
          </a>
        </div>
        <div class="start__building start__building-5">
          <div
            class="start-ny__button start-ny__button--1"
            @click="
              go('ether');
              sendEvent('Live broadcas');
            "
            :class="{ visible: elements.includes(1) }"
          >
            <div class="start-ny__button-title">Прямой <br />эфир</div>
            <div class="start-ny__button-icon"></div>
          </div>
        </div>
        <div class="start__building start__building-6"></div>
        <div class="start__building start__building-7">
          <div
            class="start-ny__button start-ny__button--9"
            :class="{ visible: elements.includes(4) }"
            @click="go('game')"
          >
            <div class="start-ny__button-title">Госуслуги <br />рядом</div>
            <div class="start-ny__button-icon"></div>
          </div>
        </div>
        <div class="start__building start__building-8"></div>
        <div class="start__building start__building-9"></div>
      </div>
      <div class="start__line"></div>
      <div class="start-ny__elements">
        <div class="start-ny__element start-ny__element-1">
          <svg
            width="85"
            height="85"
            viewBox="0 0 85 85"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_2572_1210)">
              <path
                d="M52.1387 49.3844C50.9246 46.3462 50.4719 43.0572 50.8202 39.804C51.1685 36.5508 52.307 33.4321 54.1367 30.7198C51.4236 32.5478 48.3051 33.6853 45.0522 34.0336C41.7993 34.3818 38.5106 33.9301 35.4721 32.7178C34.2636 32.2508 31.6205 30.7198 31.6205 30.7198C31.6205 30.7198 33.1441 33.3703 33.6186 34.5713C34.8291 37.6102 35.2798 40.8986 34.9316 44.1512C34.5834 47.4037 33.4468 50.5222 31.6205 53.236C34.3334 51.4076 37.452 50.2697 40.705 49.9215C43.9579 49.5733 47.2467 50.0252 50.2852 51.2379C51.4937 51.705 54.133 53.2323 54.133 53.2323C54.133 53.2323 52.6206 50.6003 52.1387 49.3844Z"
                fill="#FF4F12"
              />
              <path
                d="M63.6415 21.215C62.6925 20.2661 60.5091 22.4495 60.5091 22.4495L58.3294 24.6292C58.3294 24.6292 56.1459 26.8126 57.0949 27.7616C58.0439 28.7106 60.2236 26.5234 60.2236 26.5234L62.4033 24.3437C62.4033 24.3437 64.5867 22.1603 63.6415 21.215Z"
                fill="#FF4F12"
              />
              <path
                d="M27.7579 57.0986C26.8089 56.1497 24.6292 58.3294 24.6292 58.3294L22.4458 60.5128C22.4458 60.5128 20.266 62.6925 21.215 63.6415C22.164 64.5905 24.34 62.407 24.34 62.407L26.5234 60.2236C26.5234 60.2236 28.7031 58.0439 27.7579 57.0986Z"
                fill="#FF4F12"
              />
              <path
                d="M63.6377 63.6415C64.5867 62.6925 62.407 60.5128 62.407 60.5128L60.2236 58.3294C60.2236 58.3294 58.0439 56.1497 57.0949 57.0986C56.1459 58.0476 58.3294 60.2236 58.3294 60.2236L60.5128 62.407C60.5128 62.407 62.6925 64.5867 63.6377 63.6415Z"
                fill="#FF4F12"
              />
              <path
                d="M27.7579 27.7616C28.7068 26.8126 26.5234 24.6292 26.5234 24.6292L24.3437 22.4495C24.3437 22.4495 22.1603 20.2661 21.2113 21.215C20.2623 22.164 22.4495 24.3437 22.4495 24.3437L24.6292 26.5234C24.6292 26.5234 26.8126 28.7069 27.7579 27.7616Z"
                fill="#FF4F12"
              />
              <path
                d="M42.8898 7.13591C41.5516 7.1322 41.5664 13.6491 41.5664 13.6491L41.5627 20.14C41.5627 20.14 41.5627 26.642 42.8898 26.6569C44.2169 26.6717 44.2169 20.14 44.2169 20.14V13.638C44.2169 13.638 44.2317 7.1285 42.8898 7.13591Z"
                fill="#FF4F12"
              />
              <path
                d="M77.7206 41.9668C77.7243 43.305 71.2074 43.2902 71.2074 43.2902L64.7054 43.2902C64.7054 43.2902 58.2033 43.2902 58.1885 41.9631C58.1737 40.636 64.7054 40.636 64.7054 40.636L71.2074 40.636C71.2074 40.636 77.7243 40.6211 77.7206 41.9668Z"
                fill="#FF4F12"
              />
              <path
                d="M42.8749 76.8124C44.2168 76.8124 44.202 70.2955 44.202 70.2955L44.202 63.7935C44.202 63.7935 44.1983 57.2951 42.8749 57.2766C41.5515 57.258 41.5478 63.7935 41.5478 63.7935L41.5478 70.2955C41.5478 70.2955 41.5182 76.8272 42.8749 76.8124Z"
                fill="#FF4F12"
              />
              <path
                d="M8.04414 41.9816C8.04414 40.6396 14.561 40.6545 14.561 40.6545L21.0408 40.6619C21.0408 40.6619 27.5392 40.6656 27.5577 41.989C27.5762 43.3124 21.0408 43.3161 21.0408 43.3161L14.5388 43.3161C14.5388 43.3161 8.02561 43.3346 8.04414 41.9816Z"
                fill="#FF4F12"
              />
            </g>
            <defs>
              <clipPath id="clip0_2572_1210">
                <rect
                  width="60"
                  height="60"
                  fill="white"
                  transform="translate(42.4264) rotate(45)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="start-ny__element start-ny__element-2">
          <svg
            width="37"
            height="39"
            viewBox="0 0 37 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_2572_1220)">
              <path
                d="M27.8351 17.8945C21.7174 14.6496 17.2518 8.01044 15.9935 0C14.7353 8.01044 10.273 14.6496 4.15524 17.8945C2.96374 18.5292 0 19.5 0 19.5C0 19.5 2.96374 20.4708 4.15524 21.1021C10.273 24.3504 14.7353 30.9896 15.9935 39C17.2518 30.9896 21.7174 24.3504 27.8351 21.1021C29.0233 20.4708 31.987 19.5 31.987 19.5C31.987 19.5 29.0366 18.5292 27.8351 17.8945Z"
                fill="#FF4F12"
              />
              <path
                d="M35.7117 5.60731C33.7793 4.58903 32.3742 2.50835 31.987 0C31.5932 2.50835 30.1947 4.58903 28.279 5.60731C27.8545 5.79887 27.42 5.96657 26.9774 6.10966C27.4205 6.25118 27.8551 6.41892 28.279 6.61201C30.1947 7.63029 31.5932 9.70757 31.987 12.2159C32.3742 9.70757 33.7793 7.62689 35.7117 6.61201C36.1354 6.41835 36.57 6.2506 37.0133 6.10966C36.5706 5.96713 36.136 5.79942 35.7117 5.60731Z"
                fill="#FF4F12"
              />
            </g>
            <defs>
              <clipPath id="clip0_2572_1220">
                <rect width="37" height="39" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="start-ny__element start-ny__element-3">
          <svg
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_2572_1207)">
              <path
                d="M25.9971 16.0592C20.4284 13.1471 16.3636 7.18886 15.2182 0C14.0729 7.18886 10.0111 13.1471 4.44246 16.0592C3.3579 16.6288 0.660156 17.5 0.660156 17.5C0.660156 17.5 3.3579 18.3712 4.44246 18.9378C10.0111 21.8529 14.0729 27.8111 15.2182 35C16.3636 27.8111 20.4284 21.8529 25.9971 18.9378C27.0786 18.3712 29.7763 17.5 29.7763 17.5C29.7763 17.5 27.0907 16.6288 25.9971 16.0592Z"
                fill="#FF4F12"
              />
              <path
                d="M33.167 5.0322C31.408 4.11836 30.129 2.25109 29.7766 0C29.4181 2.25109 28.1452 4.11836 26.4014 5.0322C26.015 5.20412 25.6194 5.35462 25.2166 5.48303C25.6199 5.61003 26.0155 5.76057 26.4014 5.93386C28.1452 6.84769 29.4181 8.71192 29.7766 10.963C30.129 8.71192 31.408 6.84465 33.167 5.93386C33.5526 5.76005 33.9483 5.60951 34.3518 5.48303C33.9488 5.35512 33.5532 5.20461 33.167 5.0322Z"
                fill="#FF4F12"
              />
            </g>
            <defs>
              <clipPath id="clip0_2572_1207">
                <rect
                  width="33.6792"
                  height="35"
                  fill="white"
                  transform="translate(0.660156)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="start-ny__element start-ny__element-4">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_2572_1223)">
              <path
                d="M27.8585 18.7016C25.854 17.8417 24.0901 16.5046 22.7207 14.8069C21.3513 13.1092 20.4179 11.1023 20.0018 8.96112C19.5846 11.1018 18.6507 13.1082 17.2815 14.8057C15.9122 16.5033 14.149 17.8407 12.1451 18.7016C11.3552 19.0511 9.38757 19.5754 9.38757 19.5754C9.38757 19.5754 11.3552 20.1066 12.1451 20.4491C14.1483 21.311 15.9109 22.6487 17.28 24.3461C18.6492 26.0435 19.5834 28.0494 20.0018 30.1896C20.4188 28.0488 21.3525 26.0423 22.7218 24.3447C24.0911 22.6471 25.8545 21.3098 27.8585 20.4491C28.6484 20.0996 30.6126 19.5754 30.6126 19.5754C30.6126 19.5754 28.6589 19.0476 27.8585 18.7016Z"
                fill="#FF4F12"
              />
              <path
                d="M20.0018 0C19.1071 0 19.1071 2.05854 19.1071 2.05854V4.11359C19.1071 4.11359 19.1071 6.17213 20.0018 6.17213C20.8965 6.17213 20.893 4.11359 20.893 4.11359V2.05854C20.893 2.05854 20.893 0 20.0018 0Z"
                fill="#FF4F12"
              />
              <path
                d="M20.0018 33.8314C19.1071 33.8314 19.1071 35.8864 19.1071 35.8864V37.9449C19.1071 37.9449 19.1071 40 20.0018 40C20.8965 40 20.893 37.9449 20.893 37.9449V35.8864C20.893 35.8864 20.893 33.8314 20.0018 33.8314Z"
                fill="#FF4F12"
              />
              <path
                d="M40.0001 20.0018C40.0001 19.1071 37.945 19.1071 37.945 19.1071H35.8865C35.8865 19.1071 33.8314 19.1071 33.8314 20.0018C33.8314 20.8965 35.8865 20.893 35.8865 20.893H37.945C37.945 20.893 40.0001 20.893 40.0001 20.0018Z"
                fill="#FF4F12"
              />
              <path
                d="M6.17213 20.0018C6.17213 19.1071 4.11359 19.1071 4.11359 19.1071H2.05854C2.05854 19.1071 0 19.1071 0 20.0018C0 20.8965 2.05854 20.893 2.05854 20.893H4.11359C4.11359 20.893 6.17213 20.893 6.17213 20.0018Z"
                fill="#FF4F12"
              />
              <path
                d="M3.58235 3.14552C2.94976 3.77462 6.02884 6.83971 6.02884 6.83971L9.08694 9.90131C9.08694 9.90131 12.152 12.9664 12.7846 12.3478C13.4172 11.7292 10.3381 8.65011 10.3381 8.65011L7.27305 5.58502C7.27305 5.58502 4.21145 2.50944 3.58235 3.14552Z"
                fill="#FF4F12"
              />
              <path
                d="M36.4211 3.14544C37.0537 3.77453 33.9746 6.83963 33.9746 6.83963L30.9096 9.90472C30.9096 9.90472 27.8445 12.9698 27.2119 12.3512C26.5793 11.7326 29.6584 8.65352 29.6584 8.65352L32.7234 5.58843C32.7234 5.58843 35.7885 2.50935 36.4211 3.14544Z"
                fill="#FF4F12"
              />
              <path
                d="M36.4211 35.9983C37.0537 35.3657 33.9746 32.3006 33.9746 32.3006L30.9096 29.2355C30.9096 29.2355 27.8445 26.1739 27.2119 26.789C26.5793 27.4041 29.6584 30.4867 29.6584 30.4867L32.7234 33.5518C32.7234 33.5518 35.7885 36.6448 36.4211 35.9983Z"
                fill="#FF4F12"
              />
              <path
                d="M3.58235 35.9983C2.94976 35.3657 6.02884 32.3006 6.02884 32.3006L9.08694 29.2495C9.08694 29.2495 12.152 26.1879 12.7846 26.803C13.4172 27.4182 10.3381 30.5007 10.3381 30.5007L7.27305 33.5658C7.27305 33.5658 4.21145 36.6449 3.58235 35.9983Z"
                fill="#FF4F12"
              />
            </g>
            <defs>
              <clipPath id="clip0_2572_1223">
                <rect width="40" height="40" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="start-ny__element start-ny__element-5">
          <svg
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_2572_1197)">
              <path
                d="M41.7877 28.0524C38.7809 26.7626 36.1352 24.7569 34.081 22.2103C32.0269 19.6637 30.6268 16.6534 30.0027 13.4417C29.3768 16.6527 27.976 19.6622 25.9221 22.2086C23.8682 24.7549 21.2234 26.761 18.2176 28.0524C17.0328 28.5766 14.0813 29.363 14.0813 29.363C14.0813 29.363 17.0328 30.1599 18.2176 30.6736C21.2224 31.9665 23.8663 33.9731 25.92 36.5192C27.9737 39.0653 29.3751 42.074 30.0027 45.2844C30.6281 42.0732 32.0287 39.0634 34.0827 36.517C36.1366 33.9706 38.7817 31.9646 41.7877 30.6736C42.9725 30.1494 45.9188 29.363 45.9188 29.363C45.9188 29.363 42.9882 28.5714 41.7877 28.0524Z"
                fill="#FF4F12"
              />
              <path
                d="M30.0026 0C28.6605 0 28.6605 3.08781 28.6605 3.08781V6.17038C28.6605 6.17038 28.6605 9.25819 30.0026 9.25819C31.3447 9.25819 31.3394 6.17038 31.3394 6.17038V3.08781C31.3394 3.08781 31.3394 0 30.0026 0Z"
                fill="#FF4F12"
              />
              <path
                d="M30.0026 50.7471C28.6605 50.7471 28.6605 53.8296 28.6605 53.8296V56.9174C28.6605 56.9174 28.6605 60 30.0026 60C31.3447 60 31.3394 56.9174 31.3394 56.9174V53.8296C31.3394 53.8296 31.3394 50.7471 30.0026 50.7471Z"
                fill="#FF4F12"
              />
              <path
                d="M60 30.0026C60 28.6605 56.9174 28.6605 56.9174 28.6605H53.8296C53.8296 28.6605 50.7471 28.6605 50.7471 30.0026C50.7471 31.3447 53.8296 31.3394 53.8296 31.3394H56.9174C56.9174 31.3394 60 31.3394 60 30.0026Z"
                fill="#FF4F12"
              />
              <path
                d="M9.25819 30.0026C9.25819 28.6605 6.17038 28.6605 6.17038 28.6605H3.08781C3.08781 28.6605 0 28.6605 0 30.0026C0 31.3447 3.08781 31.3394 3.08781 31.3394H6.17038C6.17038 31.3394 9.25819 31.3394 9.25819 30.0026Z"
                fill="#FF4F12"
              />
              <path
                d="M5.37347 4.7182C4.42458 5.66184 9.04319 10.2595 9.04319 10.2595L13.6303 14.8519C13.6303 14.8519 18.228 19.4495 19.1769 18.5216C20.1258 17.5937 15.5072 12.9751 15.5072 12.9751L10.9095 8.37743C10.9095 8.37743 6.31711 3.76407 5.37347 4.7182Z"
                fill="#FF4F12"
              />
              <path
                d="M54.6317 4.71819C55.5806 5.66183 50.962 10.2595 50.962 10.2595L46.3643 14.8571C46.3643 14.8571 41.7667 19.4548 40.8178 18.5268C39.8689 17.5989 44.4875 12.9803 44.4875 12.9803L49.0852 8.38267C49.0852 8.38267 53.6828 3.76406 54.6317 4.71819Z"
                fill="#FF4F12"
              />
              <path
                d="M54.6317 53.9974C55.5806 53.0485 50.962 48.4508 50.962 48.4508L46.3643 43.8532C46.3643 43.8532 41.7667 39.2608 40.8178 40.1835C39.8689 41.1061 44.4875 45.73 44.4875 45.73L49.0852 50.3276C49.0852 50.3276 53.6828 54.9672 54.6317 53.9974Z"
                fill="#FF4F12"
              />
              <path
                d="M5.37347 53.9974C4.42458 53.0485 9.04319 48.4509 9.04319 48.4509L13.6303 43.8742C13.6303 43.8742 18.228 39.2818 19.1769 40.2045C20.1258 41.1271 15.5072 45.751 15.5072 45.751L10.9095 50.3486C10.9095 50.3486 6.31711 54.9672 5.37347 53.9974Z"
                fill="#FF4F12"
              />
            </g>
            <defs>
              <clipPath id="clip0_2572_1197">
                <rect width="60" height="60" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div> -->
    <div class="start">
      <div class="start__elements">
        <div class="start__elements-item start__elements-item-1"></div>
        <div class="start__elements-item start__elements-item-2"></div>
      </div>
      <div class="start__top">
        <div class="start__top-title">
          <span class="title title-1">защита</span> <span class="icon icon-1"></span>
        </div>
        <div class="start__top-title">
          <span class="icon icon-2"></span> <span class="title title-2">и забота</span>
        </div>
        <div class="start__top-text">Поздравляем сотрудников <br class="mob-only">ПАО «Ростелеком» с наступающими <br class="mob-only">праздниками <br class="desk-only">и предлагаем поучаствовать <br class="mob-only">в интересных активностях и мероприятиях!</div>
        <div class="start__top-element"></div>
      </div>
      <div class="start__block start__block--desk">
        <div class="start__cell">
          <div class="card size-1" :class="{ visible: elements.includes(1) }">
            <a href="javascript:void(0)" class="card__content">
              <div class="start-event start-event--1 orange">
                <div class="start-event__row">
                  <div class="start-event__icon"></div>
                  <div class="start-event__text">
                    <div class="start-event__label">TG канал</div>
                    <div class="start-event__title">все свои</div>
                  </div>
                </div>
              </div>
              <div class="start-event back">
                <div class="start-event__content">
                  <!-- <div class="start-event__icon"></div> -->
                  <div class="start-event__text"></div>
                </div>
              </div>
            </a>
          </div>
          <div class="start__cell-row">
            <div class="card size-2" :class="{ visible: elements.includes(3) }">
              <a href="javascript:void(0)" class="card__content">
                <div class="start-event start-event--2 violet">
                  <div class="start-event__content">
                    <div class="start-event__icon"></div>
                    <div class="start-event__text">
                      <div class="start-event__label">Тест-игра</div>
                      <div class="start-event__title large">шахматный турнир</div>
                    </div>
                  </div>
                </div>
                <div class="start-event back">
                  <div class="start-event__content">
                    <div class="start-event__icon"></div>
                    <div class="start-event__text">
                      <p>Раскрасьте со своим <br>ребенком веселое <br>изображение <br>на мобильном устройстве <br>или компьютере.</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div class="card size-3" :class="{ visible: elements.includes(4) }">
              <a href="javascript:void(0)" class="card__content">
                <div class="start-event start-event--3 orange">
                  <div class="start-event__content">
                    <div class="start-event__icon"></div>
                    <div class="start-event__text">
                      <div class="start-event__label">описание</div>
                      <div class="start-event__title">название механики</div>
                    </div>
                  </div>
                </div>
                <div class="start-event back">
                  <div class="start-event__content">
                    <div class="start-event__icon"></div>
                    <div class="start-event__text">
                      <p>Раскрасьте со своим <br>ребенком веселое <br>изображение <br>на мобильном устройстве <br>или компьютере.</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="start__cell-row">
            <div class="start__cell-row-column">
              <div class="card size-4" :class="{ visible: elements.includes(5) }">
                <a href="javascript:void(0)" class="card__content">
                  <div class="start-event start-event--4 orange">
                    <div class="start-event__content">
                      <div class="start-event__icon"></div>
                      <div class="start-event__text">
                        <div class="start-event__label">вебинар</div>
                        <div class="start-event__title">Забота <br>о красоте</div>
                      </div>
                    </div>
                  </div>
                  <div class="start-event back">
                    <div class="start-event__content">
                      <div class="start-event__icon"></div>
                      <div class="start-event__text">
                        <p>Раскрасьте со своим <br>ребенком веселое <br>изображение <br>на мобильном устройстве <br>или компьютере.</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="card size-4" :class="{ visible: elements.includes(7) }">
                <a href="javascript:void(0)" class="card__content">
                  <div class="start-event start-event--5 violet">
                    <div class="start-event__content">
                      <div class="start-event__icon"></div>
                      <div class="start-event__text">
                        <div class="start-event__label">благотворительная акция</div>
                        <div class="start-event__title">старость <br>в радость</div>
                      </div>
                    </div>
                  </div>
                  <div class="start-event back">
                    <div class="start-event__content">
                      <div class="start-event__icon"></div>
                      <div class="start-event__text">
                        <p>Раскрасьте со своим <br>ребенком веселое <br>изображение <br>на мобильном устройстве <br>или компьютере.</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="card size-5" :class="{ visible: elements.includes(6) }">
              <a href="javascript:void(0)" class="card__content">
                <div class="start-event start-event--6 orange">
                  <div class="start-event__content">
                    <div class="start-event__icon"></div>
                    <div class="start-event__text">
                      <div class="start-event__label">Квиз</div>
                      <div class="start-event__title large">8 марта</div>
                    </div>
                  </div>
                </div>
                <div class="start-event back">
                  <div class="start-event__content">
                    <div class="start-event__icon"></div>
                    <div class="start-event__text">
                      <p>Раскрасьте со своим <br>ребенком веселое <br>изображение <br>на мобильном устройстве <br>или компьютере.</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="start__cell">
          <div class="start__cell-row">
            <div class="card size-5" :class="{ visible: elements.includes(2) }">
              <a href="javascript:void(0)" class="card__content">
                <div class="start-event start-event--7 violet">
                  <div class="start-event__content">
                    <div class="start-event__icon"></div>
                    <div class="start-event__text">
                      <div class="start-event__label">Квиз</div>
                      <div class="start-event__title large">23 февраля</div>
                    </div>
                  </div>
                </div>
                <div class="start-event back">
                  <div class="start-event__content">
                    <div class="start-event__icon"></div>
                    <div class="start-event__text">
                      <p>Раскрасьте со своим <br>ребенком веселое <br>изображение <br>на мобильном устройстве <br>или компьютере.</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div class="start__cell-row-column">
              <div class="card size-4" :class="{ visible: elements.includes(3) }">
                <a href="javascript:void(0)" class="card__content">
                  <div class="start-event start-event--8 orange">
                    <div class="start-event__content">
                      <div class="start-event__icon"></div>
                      <div class="start-event__text">
                        <div class="start-event__label">видеоролик</div>
                        <div class="start-event__title">сегменты активности</div>
                      </div>
                    </div>
                  </div>
                  <div class="start-event back">
                    <div class="start-event__content">
                      <div class="start-event__icon"></div>
                      <div class="start-event__text">
                        <p>Раскрасьте со своим <br>ребенком веселое <br>изображение <br>на мобильном устройстве <br>или компьютере.</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="card size-4" :class="{ visible: elements.includes(4) }">
                <a href="javascript:void(0)" class="card__content">
                  <div class="start-event start-event--9 violet">
                    <div class="start-event__content">
                      <div class="start-event__icon"></div>
                      <div class="start-event__text">
                        <div class="start-event__label">вебинар</div>
                        <div class="start-event__title">защита и безопасность</div>
                      </div>
                    </div>
                  </div>
                  <div class="start-event back">
                    <div class="start-event__content">
                      <div class="start-event__icon"></div>
                      <div class="start-event__text">
                        <p>Раскрасьте со своим <br>ребенком веселое <br>изображение <br>на мобильном устройстве <br>или компьютере.</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="start__cell-row">
            <div class="card size-3" :class="{ visible: elements.includes(7) }">
              <a href="javascript:void(0)" class="card__content">
                <div class="start-event start-event--10 violet">
                  <div class="start-event__content">
                    <div class="start-event__icon"></div>
                    <div class="start-event__text">
                      <div class="start-event__label">AR-фильтры</div>
                      <div class="start-event__title">весенние <br>образы</div>
                    </div>
                  </div>
                </div>
                <div class="start-event back">
                  <div class="start-event__content">
                    <div class="start-event__icon"></div>
                    <div class="start-event__text">
                      <p>Раскрасьте со своим <br>ребенком веселое <br>изображение <br>на мобильном устройстве <br>или компьютере.</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div class="card size-2" :class="{ visible: elements.includes(8) }">
              <a href="javascript:void(0)" class="card__content">
                <div class="start-event start-event--11 orange">
                  <div class="start-event__content">
                    <div class="start-event__icon"></div>
                    <div class="start-event__text">
                      <div class="start-event__label">для детей</div>
                      <div class="start-event__title">игра-раскраска</div>
                    </div>
                  </div>
                </div>
                <div class="start-event back">
                  <div class="start-event__content">
                    <div class="start-event__icon"></div>
                    <div class="start-event__text">
                      <p>Раскрасьте со своим <br>ребенком веселое <br>изображение <br>на мобильном устройстве <br>или компьютере.</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="card size-1" :class="{ visible: elements.includes(9) }">
            <a href="javascript:void(0)" class="card__content">
              <div class="start-event start-event--12 violet">
                <div class="start-event__row">
                  <div class="start-event__icon"></div>
                  <div class="start-event__text">
                    <div class="start-event__label">Тест-игра</div>
                    <div class="start-event__title">какой ты защитник</div>
                  </div>
                </div>
              </div>
              <div class="start-event back">
                <div class="start-event__row">
                  <!-- <div class="start-event__icon"></div> -->
                  <div class="start-event__text"></div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="start__block start__block--mob">
        <div class="start__cell">
          <div class="start__cell-row">
            <div class="start__cell-row-column">
              <div class="card size-4">
                <div class="card__content">
                  <div class="start-event start-event--5 violet">
                    <div class="start-event__content">
                      <div class="start-event__icon"></div>
                      <div class="start-event__text">
                        <div class="start-event__label">благотворительная <br>акция</div>
                        <div class="start-event__title">старость <br>в радость</div>
                      </div>
                    </div>
                  </div>
                  <a href="javascript:void(0)" target="_blank" class="start-event back">
                    <div class="start-event__content">
                      <div class="start-event__icon"></div>
                      <div class="start-event__text">
                        <p>Раскрасьте со своим <br>ребенком веселое <br>изображение <br>на мобильном устройстве <br>или компьютере.</p>
                      </div>
                      <div class="start-event__button"></div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="card size-4">
                <div class="card__content">
                  <div class="start-event start-event--4 orange">
                    <div class="start-event__content">
                      <div class="start-event__icon"></div>
                      <div class="start-event__text">
                        <div class="start-event__label">вебинар</div>
                        <div class="start-event__title">красота <br>и стиль</div>
                      </div>
                    </div>
                  </div>
                  <a href="javascript:void(0)" target="_blank" class="start-event back">
                    <div class="start-event__content">
                      <div class="start-event__icon"></div>
                      <div class="start-event__text">
                        <p>Раскрасьте со своим <br>ребенком веселое <br>изображение <br>на мобильном устройстве <br>или компьютере.</p>
                      </div>
                      <div class="start-event__button"></div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="card size-2">
            <div class="card__content">
              <div class="start-event start-event--2 violet">
                <div class="start-event__content">
                  <div class="start-event__icon"></div>
                  <div class="start-event__text">
                    <div class="start-event__label">Тест-игра</div>
                    <div class="start-event__title large">шахматный турнир</div>
                  </div>
                </div>
              </div>
              <a href="javascript:void(0)" target="_blank" class="start-event back">
                <div class="start-event__content">
                  <div class="start-event__icon"></div>
                  <div class="start-event__text">
                    <p>Раскрасьте со своим <br>ребенком веселое <br>изображение <br>на мобильном устройстве <br>или компьютере.</p>
                  </div>
                  <div class="start-event__button"></div>
                </div>
              </a>
            </div>
          </div>
          <div class="start__cell-row">
            <div class="start__cell-row-column">
              <div class="card size-3">
                <div class="card__content">
                  <div class="start-event start-event--3 orange">
                    <div class="start-event__content">
                      <div class="start-event__icon"></div>
                      <div class="start-event__text">
                        <div class="start-event__label">описание</div>
                        <div class="start-event__title">название механики</div>
                      </div>
                    </div>
                  </div>
                  <a href="javascript:void(0)" target="_blank" class="start-event back">
                    <div class="start-event__content">
                      <div class="start-event__icon"></div>
                      <div class="start-event__text">
                        <p>Раскрасьте со своим <br>ребенком веселое <br>изображение <br>на мобильном устройстве <br>или компьютере.</p>
                      </div>
                      <div class="start-event__button"></div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="card size-4">
                <div class="card__content">
                  <div class="start-event start-event--9 violet">
                    <div class="start-event__content">
                      <div class="start-event__icon"></div>
                      <div class="start-event__text">
                        <div class="start-event__label">вебинар</div>
                        <div class="start-event__title">защита и безопасность</div>
                      </div>
                    </div>
                  </div>
                  <a href="javascript:void(0)" target="_blank" class="start-event back">
                    <div class="start-event__content">
                      <div class="start-event__icon"></div>
                      <div class="start-event__text">
                        <p>Раскрасьте со своим <br>ребенком веселое <br>изображение <br>на мобильном <br class="mob-only">устройстве <br class="desk-only">или <br class="mob-only">компьютере.</p>
                      </div>
                      <div class="start-event__button"></div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="card size-5">
            <div class="card__content">
              <div class="start-event start-event--7 violet">
                <div class="start-event__content">
                  <div class="start-event__icon"></div>
                  <div class="start-event__text">
                    <div class="start-event__label">Квиз</div>
                    <div class="start-event__title large">23 февраля</div>
                  </div>
                </div>
              </div>
              <a href="javascript:void(0)" target="_blank" class="start-event back">
                <div class="start-event__content">
                  <div class="start-event__icon"></div>
                  <div class="start-event__text">
                    <p>Раскрасьте со своим <br>ребенком веселое <br>изображение <br>на мобильном устройстве <br>или компьютере.</p>
                  </div>
                  <div class="start-event__button"></div>
                </div>
              </a>
            </div>
          </div>
          <div class="card size-2">
            <div class="card__content">
              <div class="start-event start-event--11 orange">
                <div class="start-event__content">
                  <div class="start-event__icon"></div>
                  <div class="start-event__text">
                    <div class="start-event__label">для детей</div>
                    <div class="start-event__title">игра-раскраска</div>
                  </div>
                </div>
              </div>
              <a href="javascript:void(0)" target="_blank" class="start-event back">
                <div class="start-event__content">
                  <div class="start-event__icon"></div>
                  <div class="start-event__text">
                    <p>Раскрасьте со своим <br>ребенком веселое <br>изображение <br>на мобильном устройстве <br>или компьютере.</p>
                  </div>
                  <div class="start-event__button"></div>
                </div>
              </a>
            </div>
          </div>
          <div class="start__cell-row">
            <div class="start__cell-row-column">
              <div class="card size-3">
                <div class="card__content">
                  <div class="start-event start-event--10 violet">
                    <div class="start-event__content">
                      <div class="start-event__icon"></div>
                      <div class="start-event__text">
                        <div class="start-event__label">AR-фильтры</div>
                        <div class="start-event__title">весенние <br>образы</div>
                      </div>
                    </div>
                  </div>
                  <a href="javascript:void(0)" target="_blank" class="start-event back">
                    <div class="start-event__content">
                      <div class="start-event__icon"></div>
                      <div class="start-event__text">
                        <p>Раскрасьте со своим <br>ребенком веселое <br>изображение <br>на мобильном устройстве <br>или компьютере.</p>
                      </div>
                      <div class="start-event__button"></div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="card size-1">
                <div class="card__content">
                  <div class="start-event start-event--1 orange">
                    <div class="start-event__content">
                      <div class="start-event__icon"></div>
                      <div class="start-event__text">
                        <div class="start-event__label">TG канал</div>
                        <div class="start-event__title">все свои</div>
                      </div>
                    </div>
                  </div>
                  <a href="javascript:void(0)" target="_blank" class="start-event back">
                    <div class="start-event__content">
                      <div class="start-event__icon"></div>
                      <div class="start-event__text">
                        <p>Раскрасьте со своим <br>ребенком веселое <br>изображение <br>на мобильном устройстве <br>или компьютере.</p>
                      </div>
                      <div class="start-event__button"></div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="card size-5">
            <div class="card__content">
              <div class="start-event start-event--6 orange">
                <div class="start-event__content">
                  <div class="start-event__icon"></div>
                  <div class="start-event__text">
                    <div class="start-event__label">Квиз</div>
                    <div class="start-event__title large">8 марта</div>
                  </div>
                </div>
              </div>
              <a href="javascript:void(0)" target="_blank" class="start-event back">
                <div class="start-event__content">
                  <div class="start-event__icon"></div>
                  <div class="start-event__text">
                    <p>Раскрасьте со своим <br>ребенком веселое <br>изображение <br>на мобильном устройстве <br>или компьютере.</p>
                  </div>
                  <div class="start-event__button"></div>
                </div>
              </a>
            </div>
          </div>
          <div class="start__cell-row">
            <div class="start__cell-row-column">
              <div class="card size-4">
                <div class="card__content">
                  <div class="start-event start-event--8 orange">
                    <div class="start-event__content">
                      <div class="start-event__icon"></div>
                      <div class="start-event__text">
                        <div class="start-event__label">видеоролик</div>
                        <div class="start-event__title">сегменты активности</div>
                      </div>
                    </div>
                  </div>
                  <a href="javascript:void(0)" target="_blank" class="start-event back">
                    <div class="start-event__content">
                      <div class="start-event__icon"></div>
                      <div class="start-event__text">
                        <p>Раскрасьте со своим <br>ребенком веселое <br>изображение <br>на мобильном устройстве <br>или компьютере.</p>
                      </div>
                      <div class="start-event__button"></div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="card size-1">
                <div class="card__content">
                  <div class="start-event start-event--12 violet">
                    <div class="start-event__content">
                      <div class="start-event__icon"></div>
                      <div class="start-event__text">
                        <div class="start-event__label">Тест-игра</div>
                        <div class="start-event__title">какой ты защитник</div>
                      </div>
                    </div>
                  </div>
                  <a href="javascript:void(0)" target="_blank" class="start-event back">
                    <div class="start-event__content">
                      <div class="start-event__icon"></div>
                      <div class="start-event__text">
                        <p>Раскрасьте со своим <br>ребенком веселое <br>изображение <br>на мобильном устройстве <br>или компьютере.</p>
                      </div>
                      <div class="start-event__button"></div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="start__bottom">
        <div class="start__bottom-element"></div>
        <button class="start__bottom-button">К датам и эпохам <span class="arrow"></span></button>
      </div>
    </div>
    <audio
      ref="audioElementNy"
      src="@/assets/sounds/newyear.mp3"
      preload="auto"
      id="transform"
      loop
    ></audio>
  </div>
</template>

<script>
export default {
  name: "StartScreen",
  props: {
    type: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isShowSecondDate: true,
      nextScreen: false,
      elements: [],
    };
  },

  mounted() {
    this.$refs.audioElementNy.play();
    document.addEventListener("visibilitychange", this.handleVisibilityChange);

    let currentNumber = 1;
    const interval = setInterval(() => {
      if (currentNumber <= 9) {
        this.elements.push(currentNumber);
        console.log(this.elements);
        currentNumber++;
      } else {
        clearInterval(interval);
      }
    }, 500);
    // const image2 = document.querySelector(".start__group");
    // window.addEventListener("mousemove", (e) => {
    //   const centerX = window.innerWidth / 2;
    //   const centerY = window.innerHeight / 2;
    //   const moveX = (e.clientX - centerX) * 0.01;
    //   const moveY = (e.clientY - centerY) * 0.01;
    //   image2.style.transform = `translate(${moveX}px, ${moveY}px)`;
    // });
  },
  beforeDestroy() {
    document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    this.$refs.audioElementNy.pause();
  },
  methods: {
    handleVisibilityChange() {
      if (document.hidden) {
        this.$refs.audioElementNy.pause();
      } else {
        this.$refs.audioElementNy.play();
      }
    },
    go(query_param) {
      this.$router.push({ path: "/", query: { pin: query_param } });
      this.$emit("go");
    },
    start() {
      this.nextScreen = true;
      if (this.$route.query.pin) {
        this.$emit("go");
      }
    },
  },
};
</script>

<style lang="sass">
@import '@/assets/sass/functions.sass'
.start
  &__elements 
    @media screen and (max-width: 1000px)
      display: none
    &-item 
      position: absolute
      background-size: contain
      background-repeat: no-repeat
      &-1 
        width: vw(401px)
        height: vw(473px)
        top: 0
        left: vw(30px)
        background-image: url(../assets/img/origami-1.png)
      &-2 
        width: vw(522px)
        height: vw(411px)
        top: 0
        right: vw(-3px)
        background-image: url(../assets/img/origami-2.png)
  &__top 
    display: flex
    flex-direction: column
    align-items: center
    padding-top: vw(79px)
    @media screen and (max-width: 1000px)
      padding-top: vwm(33px)
    &-title 
      font-size: vw(52px)
      color: #fff
      text-transform: uppercase
      display: flex
      align-items: center
      letter-spacing: vw(2.8px)
      @media screen and (max-width: 1000px)
        font-size: vwm(37px)
        letter-spacing: vwm(2.2px)
      .title 
        width: vw(305px)
        height: vw(93px)
        display: flex
        align-items: center
        justify-content: center
        padding-top: vw(5px)
        @media screen and (max-width: 1000px)
          width: vwm(218px)
          height: vwm(66px)
      .title-1 
        background-color: #FF4F12
        padding-left: vw(11px)
        @media screen and (max-width: 1000px)
          padding-left: vwm(10px)
      .title-2
        background-color: #7700FF
        padding-left: vw(2px)
        @media screen and (max-width: 1000px)
          padding-left: vwm(3px)
      .icon 
        width: vw(93px)
        height: vw(93px)
        background-size: contain
        background-repeat: no-repeat
        @media screen and (max-width: 1000px)
          width: vwm(66px)
          height: vwm(66px)
        &-1 
          background-image: url(../assets/img/icons/special-1.jpg)
        &-2
          background-image: url(../assets/img/icons/special-2.jpg)
    &-text
      font-size: vw(15px)
      line-height: vw(22px)
      color: #fff
      text-align: center
      margin-top: vw(16px)
      letter-spacing: 0.1px
      text-shadow: 0 vw(2px) vw(4px) rgba(35, 0, 74, 0.58)
      @media screen and (max-width: 1000px)
        font-size: vwm(15px)
        line-height: vwm(22px)
        margin-top: vwm(16px)
        text-shadow: 0 vwm(2px) vwm(4px) rgba(35, 0, 74, 0.58)
    &-element 
      @media screen and (max-width: 1000px)
        width: vwm(376px)
        height: vwm(277px)
        background-size: contain
        background-repeat: no-repeat
        background-image: url(../assets/img/origami-mob-1.png)
        margin-top: vwm(-12px)
  &__bottom 
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    margin-bottom: vw(50px)
    @media screen and (max-width: 1000px)
      margin-bottom: vwm(50px)
    &-button 
      background-color: #FF4F12
      height: vw(51px)
      width: vw(246px)
      border-radius: vw(2px)
      box-shadow: 0 vw(15px) vw(30px) 0 rgba(0, 0, 0, 0.22)
      color: #fff
      font-size: vw(16px)
      font-weight: 500
      font-family: 'Tektur'
      text-transform: uppercase
      display: flex
      align-items: center
      justify-content: center
      cursor: pointer
      letter-spacing: 0.8px
      @media screen and (max-width: 1000px) 
        font-size: vwm(16px)
        width: vwm(246px)
        height: vwm(51px)
        border-radius: vwm(2px)
        box-shadow: 0 vwm(15px) vwm(30px) 0 rgba(0, 0, 0, 0.22)
        margin-top: vwm(-64px)
      span 
        &.arrow 
          width: vw(14px)
          height: vw(14px)
          background-image: url(../assets/img/arrow-right.svg)
          background-size: contain
          background-repeat: no-repeat
          display: block
          margin-left: vw(10px)
          @media screen and (max-width: 1000px)
            width: vwm(14px)
            height: vwm(14px)
            margin-left: vwm(10px)
    &-element 
      @media screen and (max-width: 1000px)
        width: vwm(324px)
        height: vwm(395px)
        background-size: contain
        background-repeat: no-repeat
        background-image: url(../assets/img/origami-mob-2.png)
        margin-top: vwm(-79px)
        margin-left: vwm(17px)
  &__block 
    width: vw(1400px)
    height: vw(740px)
    margin: auto
    margin-top: vw(60px)
    margin-bottom: vw(40px)
    display: flex
    &--desk 
      @media screen and (max-width: 1000px)
        display: none
    &--mob 
      display: none
      @media screen and (max-width: 1000px) 
        display: block
    @media screen and (max-width: 1000px)
      width: vwm(344px)
      height: auto
      margin-top: vwm(-53px)
      margin-bottom: vwm(20px)
  &__cell 
    width: vw(700px)
    @media screen and (max-width: 1000px) 
      width: 100%
    &-row 
      display: flex
      &-column 
        @media screen and (max-width: 1000px) 
          display: flex
.card 
  perspective: vw(1500px)
  opacity: 0
  transition: all 50ms
  transform: translateY(-20px)
  &.visible
    opacity: 1
    transform: translateY(0)
  &:hover 
    .card__content 
      transform: rotateY(180deg)
  &__content 
    display: block
    position: relative
    width: 100%
    height: 100%
    transform-style: preserve-3d
    transition: transform 0.8s cubic-bezier(0.75, 0, 0.85, 1)
  &.size-1
    width: 100%
    height: vw(90px)
    @media screen and (max-width: 1000px)   
      width: vwm(172px)
      height: vwm(172px)
  &.size-2
    width: vw(467px)
    height: vw(186px)
    @media screen and (max-width: 1000px)  
      width: 100%
      height: vwm(172px)
  &.size-3
    width: vw(233px)
    height: vw(186px)
    @media screen and (max-width: 1000px)  
      width: vwm(172px)
      height: vwm(172px)
  &.size-4
    width: vw(233px)
    height: vw(232px)
    @media screen and (max-width: 1000px)  
      width: vwm(172px)
      height: vwm(172px)
  &.size-5
    width: vw(567px)
    height: vw(464px)
    @media screen and (max-width: 1000px) 
      width: 100%
      height: vwm(344px)
.start-event 
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  backface-visibility: hidden
  transform-style: preserve-3d
  border: vw(1px) solid rgba(255, 255, 255, 0.3)
  color: #fff
  display: flex
  align-items: center
  justify-content: center
  &.back 
    background-color: #fff
    transform: rotateY(180deg)
    .start-event__icon  
      width: vw(53px)
      height: vw(48px)
      background-image: url(../assets/img/heart.png)
      margin-bottom: vw(9px)
      @media screen and (max-width: 1000px)
        width: vwm(43px)
        height: vwm(39px)
        margin-bottom: vwm(9px)
  &--1 
    padding-top: vw(1px)
    @media screen and (max-width: 1000px)
      padding-top: 0
    .start-event__label 
      margin-bottom: vw(3px)
      @media screen and (max-width: 1000px)
        margin-bottom: vwm(7px)
    .start-event__icon  
      width: vw(61px)
      height: vw(60px)
      background-image: url(../assets/img/icons/icon-1.svg)
      margin-top: vw(-2px)
      margin-right: vw(18px)
      @media screen and (max-width: 1000px)
        width: vwm(49px)
        height: vwm(49px)
        margin-bottom: vwm(10px)
        margin-right: 0
        margin-top: 0
  &--2
    padding-top: vw(3px)
    @media screen and (max-width: 1000px)  
      padding-top: 0
    .start-event__label 
      margin-bottom: vw(7px)
      @media screen and (max-width: 1000px)  
        margin-bottom: vwm(7px)
    .start-event__icon  
      width: vw(46px)
      height: vw(73px)
      background-image: url(../assets/img/icons/icon-2.svg)
      margin-bottom: vw(11px)
      @media screen and (max-width: 1000px)  
        width: vwm(47px)
        height: vwm(74px)
        margin-bottom: vwm(8px)
  &--3
    padding-top: vw(3px)
    @media screen and (max-width: 1000px)  
      padding-top: 0
    .start-event__label 
      margin-bottom: vw(7px)
      @media screen and (max-width: 1000px)  
        margin-bottom: vwm(7px)
    .start-event__icon  
      width: vw(59px)
      height: vw(64px)
      background-image: url(../assets/img/icons/icon-3.svg)
      margin-bottom: vw(12px)
      @media screen and (max-width: 1000px)  
        width: vwm(51px)
        height: vwm(55px)
        margin-bottom: vwm(10px)
  &--4
    padding-top: vw(3px)
    @media screen and (max-width: 1000px) 
      padding-top: vwm(3px)
    .start-event__label 
      margin-bottom: vw(7px)
      @media screen and (max-width: 1000px) 
        margin-bottom: vwm(6px)
    .start-event__icon  
      width: vw(104px)
      height: vw(67px)
      background-image: url(../assets/img/icons/icon-4.svg)
      margin-bottom: vw(12px)
      @media screen and (max-width: 1000px) 
        width: vwm(85px)
        height: vwm(55px)
        margin-bottom: vwm(10px)
  &--5
    .start-event__label 
      margin-bottom: vw(7px)
      @media screen and (max-width: 1000px) 
        margin-bottom: vwm(6px)
    .start-event__icon  
      width: vw(72px)
      height: vw(64px)
      background-image: url(../assets/img/icons/icon-5.svg)
      margin-bottom: vw(12px)
      @media screen and (max-width: 1000px) 
        width: vwm(60px)
        height: vwm(54px)
        margin-bottom: vwm(9px)
  &--6
    padding-top: vw(3px)
    @media screen and (max-width: 1000px)
      padding-top: vwm(3px)
    .start-event__label 
      margin-bottom: vw(7px)
      @media screen and (max-width: 1000px)
        font-size: vwm(12px)
        line-height: vwm(16px)
        margin-bottom: vwm(6px)
    .start-event__icon  
      width: vw(87px)
      height: vw(79px)
      background-image: url(../assets/img/icons/icon-6.png)
      margin-bottom: vw(12px)
      @media screen and (max-width: 1000px)
        width: vwm(86px)
        height: vwm(78px)
        margin-bottom: vwm(12px)
    .start-event__title.large 
      @media screen and (max-width: 1000px)
        font-size: vwm(30px)
        line-height: vwm(30px)
        letter-spacing: vwm(1.5px)
  &--7
    padding-top: vw(3px)
    @media screen and (max-width: 1000px)
      padding-top: vwm(3px)
    .start-event__label 
      margin-bottom: vw(7px)
      @media screen and (max-width: 1000px)  
        font-size: vwm(12px)
        line-height: vwm(16px)
        margin-bottom: vwm(4px)
    .start-event__icon  
      width: vw(126px)
      height: vw(54px)
      background-image: url(../assets/img/icons/icon-7.svg)
      margin-bottom: vw(11px)
      @media screen and (max-width: 1000px)  
        width: vwm(126px)
        height: vwm(53px)
        margin-bottom: vwm(12px)
    .start-event__title.large
      @media screen and (max-width: 1000px)  
        font-size: vwm(30px)
        line-height: vwm(33px)
        letter-spacing: vwm(1.5px)
  &--8
    padding-top: vw(2px)
    @media screen and (max-width: 1000px)
      padding-top: vwm(2px)
    .start-event__label 
      margin-bottom: vw(8px)
      @media screen and (max-width: 1000px)
        margin-bottom: vwm(7px)
    .start-event__icon  
      width: vw(54px)
      height: vw(54px)
      background-image: url(../assets/img/icons/icon-8.svg)
      margin-bottom: vw(12px)
      @media screen and (max-width: 1000px)
        width: vwm(54px)
        height: vwm(54px)
        margin-bottom: vwm(9px)
  &--9
    padding-top: vw(2px)
    @media screen and (max-width: 1000px)  
      padding-top: 0
    .start-event__label 
      margin-bottom: vw(8px)
      @media screen and (max-width: 1000px)  
        margin-bottom: vwm(7px)
    .start-event__icon  
      width: vw(60px)
      height: vw(60px)
      background-image: url(../assets/img/icons/icon-1.svg)
      margin-bottom: vw(12px)
      @media screen and (max-width: 1000px)  
        width: vwm(49px)
        height: vwm(49px)
        margin-bottom: vwm(10px)
  &--10
    @media screen and (max-width: 1000px)
      padding-top: vwm(2px)
    .start-event__label 
      margin-bottom: vw(7px)
      @media screen and (max-width: 1000px)
        margin-bottom: vwm(6px)
    .start-event__icon  
      width: vw(72px)
      height: vw(64px)
      background-image: url(../assets/img/icons/icon-5.svg)
      transform: scaleX(-1)
      margin-bottom: vw(12px)
      @media screen and (max-width: 1000px)
        width: vwm(60px)
        height: vwm(54px)
        margin-bottom: vwm(9px)
  &--11
    padding-top: vw(3px)
    @media screen and (max-width: 1000px)
      padding-top: vwm(2px)
    .start-event__label 
      margin-bottom: vw(7px)
      @media screen and (max-width: 1000px)
        margin-bottom: vwm(6px)
    .start-event__icon  
      width: vw(59px)
      height: vw(64px)
      background-image: url(../assets/img/icons/icon-3.svg)
      margin-bottom: vw(12px)
      @media screen and (max-width: 1000px)
        width: vwm(73px)
        height: vwm(78px)
        margin-bottom: vwm(10px)
  &--12
    padding-top: vw(1px)
    @media screen and (max-width: 1000px)
      padding-top: vwm(3px)
    .start-event__label 
      margin-bottom: vw(3px)
      @media screen and (max-width: 1000px)
        margin-bottom: vwm(6px)
    .start-event__icon  
      width: vw(85px)
      height: vw(50px)
      background-image: url(../assets/img/icons/icon-9.svg)
      margin-top: vw(-2px)
      margin-right: vw(18px)
      @media screen and (max-width: 1000px)
        width: vwm(75px)
        height: vwm(44px)
        margin-bottom: vwm(10px)
        margin-top: 0
        margin-right: 0
  &.orange 
    background-color: rgba(255, 79, 18, 0.5)
  &.violet 
    background-color: rgba(102, 0, 219, 0.5)
  &__row 
    display: flex
    align-items: center
    justify-content: center
  &__content 
    display: flex
    flex-direction: column
    align-items: center
    //transform: translateZ(80px) scale(0.94)
  &__icon 
    background-size: contain
    background-repeat: no-repeat
  &__text 
    p 
      color: #585858
      font-size: vw(14px)
      line-height: vw(14px)
      font-weight: 500
      text-align: center
      @media screen and (max-width: 1000px)
        font-size: vwm(12px)
        line-height: vwm(12px)
  &__label 
    font-size: vw(12px)
    font-weight: 500
    text-transform: uppercase
    letter-spacing: 0.6px
    text-align: center
    @media screen and (max-width: 1000px) 
      font-size: vwm(10px)
      line-height: vwm(13px)
      letter-spacing: 0.4px
  &__title 
    font-size: vw(23px)
    line-height: vw(23px)
    font-weight: 500
    text-transform: uppercase
    font-family: 'Tektur'
    letter-spacing: vw(1.1px)
    text-align: center
    @media screen and (max-width: 1000px) 
      font-size: vwm(18px)
      line-height: vwm(18px)
      letter-spacing: 0.9px
    &.large 
      font-size: vw(30px)
      line-height: vw(30px)
      letter-spacing: vw(1.5px)
      @media screen and (max-width: 1000px)  
        font-size: vwm(18px)
        line-height: vwm(18px)
        letter-spacing: 0.9px
  &__button 
    width: vwm(19px)
    height: vwm(19px)
    background-image: url(../assets/img/circle-button.svg)
    background-size: contain
    background-repeat: no-repeat
    margin-top: vwm(6px)
.start-ny
  &__element
    position: absolute
    @media screen and (max-width: 1000px)
      display: none
    &-1
      left: vw(173px)
      top: vw(60px)
    &-2
      left: vw(534px)
      top: vw(154px)
    &-3
      right: vw(502px)
      top: vw(78px)
    &-4
      right: vw(268px)
      top: vw(62px)
    &-5
      right: vw(155px)
      top: vw(85px)
  &__buttons
    &.for-visitors
      .start-ny__button--2
        left: vw(367px)
        top: vw(123px)
        @media screen and (max-width: 1000px)
          top: vwm(215px)
          left: auto
          right: vwm(21px)
      .start-ny__button--3
        left: vw(499px)
        top: vw(241px)
        @media screen and (max-width: 1000px)
          left: vwm(137px)
          top: vwm(120px)
      .start-ny__button--4
        left: vw(703px)
        top: vw(221px)
        @media screen and (max-width: 1000px)
          top: vwm(340px)
          left: auto
          right: vwm(99px)
        .start-ny__button-title
          background-color: #6600DB
          padding: vw(12px) vw(22px)
      .start-ny__button--9
        right: vw(358px)
        top: vw(136px)
        @media screen and (max-width: 1000px)
          left: vwm(32px)
          right: auto
          top: vwm(286px)
  &__button
    position: absolute
    display: flex
    flex-direction: column
    align-items: center
    cursor: pointer
    transition: all 300ms
    z-index: 1000
    &.disabled
      cursor: default
      .start-ny__button-title
        opacity: 0.5
      .start-ny__button-icon
        opacity: 0.5
    &-title
      font-size: vw(15px)
      line-height: vw(19px)
      text-transform: uppercase
      font-family: 'Tektur'
      font-weight: 600
      color: #fff
      background-color: #FF4F12
      border-radius: vw(7px)
      text-align: center
      margin-bottom: vw(9px)
      display: inline-block
      padding: vw(2px) vw(9px)
      box-shadow: 0px 0px 10px 0px rgba(41, 0, 0, 0.2)
      @media screen and (max-width: 1000px)
        font-size: vwm(10px)
        line-height: vwm(13px)
        border-radius: vwm(6px)
        margin-bottom: vwm(8px)
        padding: vwm(2px) vwm(9px)
    &-subtitle
      font-size: vw(11px)
      line-height: vw(11px)
      color: #433750
      font-weight: 500
      background-color: #fff
      box-shadow: 0px 0px 10px 0px rgba(41, 0, 0, 0.2)
      border-radius: vw(7px)
      text-align: center
      padding: vw(6px) vw(10px) vw(4px) vw(10px)
      margin-bottom: vw(9px)
      @media screen and (max-width: 1000px)
        font-size: vwm(11px)
        line-height: vwm(11px)
        border-radius: vwm(7px)
        padding: vwm(6px) vwm(10px) vwm(4px) vwm(10px)
        margin-bottom: 0
        margin-top: vwm(2px)
    &-icon
      width: vw(89px)
      height: vw(89px)
      background-size: contain
      background-repeat: no-repeat
      background-position: 50% 50%
      transition: none !important
      @media screen and (max-width: 1000px)
        width: vwm(72px)
        height: vwm(72px)
    &--1
      top: vw(-28px)
      left: vw(11px)
      @media screen and (max-width: 1000px)
        left: auto
        right: vwm(17px)
        top: vwm(-54px)
      .start-ny__button-icon
        background-image: url(../assets/img/button-1.svg)
      &:hover
        .start-ny__button-icon
          background-image: url(../assets/img/button-1-hover.svg)
    &--2
      top: vw(-155px)
      left: vw(21px)
      @media screen and (max-width: 1000px)
        top: vwm(-122px)
        left: vwm(-5px)
      .start-ny__button-icon
        background-image: url(../assets/img/button-2.svg)
      &:hover
        .start-ny__button-icon
          background-image: url(../assets/img/button-2-hover.svg)
    &--3
      top: vw(-160px)
      left: vw(33px)
      @media screen and (max-width: 1000px)
        top: vwm(-100px)
        left: vwm(10px)
      .start-ny__button-icon
        background-image: url(../assets/img/button-3.svg)
      &:hover
        .start-ny__button-icon
          background-image: url(../assets/img/button-3-hover.svg)
    &--4
      top: vw(-159px)
      left: vw(-9px)
      @media screen and (max-width: 1000px)
        top: vwm(-124px)
        left: vwm(2px)
      .start-ny__button-icon
        background-image: url(../assets/img/button-4.svg)
      &:hover
        .start-ny__button-icon
          background-image: url(../assets/img/button-4-hover.svg)
    &--5
      top: vw(-149px)
      left: vw(81px)
      @media screen and (max-width: 1000px)
        top: vwm(-112px)
        left: vwm(10px)
      .start-ny__button-icon
        background-image: url(../assets/img/button-5.svg)
      &:hover
        .start-ny__button-icon
          background-image: url(../assets/img/button-5-hover.svg)
    &--6
      top: vw(-76px)
      left: vw(-7px)
      @media screen and (max-width: 1000px)
        top: vwm(-98px)
        left: vwm(46px)
      .start-ny__button-title
        white-space: nowrap
        margin-bottom: vw(5px)
        @media screen and (max-width: 1000px)
          order: 1
          margin-bottom: vwm(8px)
      .start-ny__button-subtitle
        @media screen and (max-width: 1000px)
          order: 3
      .start-ny__button-icon
        background-image: url(../assets/img/button-6.svg)
        @media screen and (max-width: 1000px)
          order: 2
      &:hover
        .start-ny__button-icon
          background-image: url(../assets/img/button-6-hover.svg)
    &--7
      top: vw(-170px)
      left: vw(6px)
      @media screen and (max-width: 1000px)
        top: vwm(-17px)
        left: vwm(4px)
      .start-ny__button-icon
        background-image: url(../assets/img/button-7.svg)
      &:hover
        .start-ny__button-icon
          background-image: url(../assets/img/button-7-hover.svg)
    &--8
      top: vw(-129px)
      left: vw(-11px)
      @media screen and (max-width: 1000px)
        top: vwm(-31px)
        right: vwm(0px)
      .start-ny__button-title
        white-space: nowrap
      .start-ny__button-icon
        background-image: url(../assets/img/button-8.svg)
      &:hover
        .start-ny__button-icon
          background-image: url(../assets/img/button-8-hover.svg)
    &--9
      top: vw(-154px)
      left: vw(-3px)
      @media screen and (max-width: 1000px)
        top: vwm(-112px)
        left: vwm(-7px)
      .start-ny__button-title
        white-space: nowrap
      .start-ny__button-icon
        background-image: url(../assets/img/button-9.svg)
      &:hover
        .start-ny__button-icon
          background-image: url(../assets/img/button-9-hover.svg)
.start
  width: 100vw
  height: 100vh
  height: var(--app-height)
  position: fixed
  top: 0
  left: 0
  z-index: 99999
  //cursor: pointer
  overflow: hidden
  // стили к 23 февраля и 8 марта
  background-image: url('../assets/img/start-bg-gradient.jpg')
  background-size: cover
  background-position: 50% 0
  overflow-y: scroll
  @media screen and (max-width: 1000px)
    background-image: url('../assets/img/start-bg-gradient-mob.jpg')
  &--ny
    background-image: url(../assets/img/ny-bg.jpg)
    background-size: cover
    background-repeat: no-repeat
    background-position: 50% 50%
    cursor: auto
    @media screen and (max-width: 1000px)
      background-image: url(../assets/img/ny-bg-mob.jpg)
    .start__title
      background-image: url(../assets/img/ny-title.svg)
      width: vw(276px)
      height: vw(136px)
      left: vw(-1px)
      right: 0
      margin: auto
      top: auto
      bottom: vw(533px)
      @media screen and (max-width: 1000px)
        width: vwm(163px)
        height: vwm(83px)
        top: vwm(21px)
        left: vwm(2px)
        bottom: auto
  &--new
    background-image: url(../assets/img/welcome-bg.jpg)
    background-size: cover
    background-repeat: no-repeat
    background-position: 50% 50%
    cursor: auto
    @media screen and (max-width: 1000px)
      background-image: url(../assets/img/start-screen-mob.jpg)
      &:after
        content: ''
        width: 100%
        height: 100%
        background-size: cover
        background-image: url('../assets/img/circles.svg')
        position: absolute
        top: 0
        left: 0
        background-repeat: no-repeat
    .start__button
      @media screen and (max-width: 1000px)
        bottom: vwm(97px)
        top: auto
    .start__button-link
      height: vw(69px)
      padding: 0
      width: vw(268px)
      border: vw(4px) solid #fff
      border-radius: vw(16px)
      font-size: vw(19px)
      line-height: vw(61px)
      font-family: 'Tektur'
      letter-spacing: 1px
      box-shadow: 0px vw(6.43px) vw(7.35px) 0px rgba(41, 0, 0, 0.25)
      @media screen and (max-width: 1000px)
        height: vwm(59px)
        width: vwm(219px)
        border-width: vwm(4px)
        border-radius: vwm(6px)
        font-size: vwm(16px)
        line-height: vwm(52px)
  &--info
    background-image: url(../assets/img/welcome-bg.jpg)
    background-size: cover
    background-position: 50% 50%
    cursor: auto
    &:after
      content: ''
      width: 100%
      height: 100%
      background-color: rgba(31, 0, 65, 0.7)
      position: absolute
      top: 0
      left: 0
      -webkit-backdrop-filter: blur(5px)
      backdrop-filter: blur(5px)
  &__shadow
    position: absolute
    background-size: contain
    background-repeat: no-repeat
    @media screen and (max-width: 1000px)
      display: none
    &-1
      width: vw(363px)
      height: vw(255px)
      background-image: url(../assets/img/shadow-1.svg)
      bottom: 0
      left: 0
    &-2
      width: vw(202px)
      height: vw(261px)
      background-image: url(../assets/img/shadow-2.svg)
      bottom: 0
      left: vw(473px)
    &-3
      width: vw(805px)
      height: vw(242px)
      background-image: url(../assets/img/shadow-3.svg)
      bottom: 0
      right: 0
  &__bridge
    position: absolute
    background-size: contain
    background-repeat: no-repeat
    width: vw(672px)
    height: vw(180px)
    background-image: url(../assets/img/bridge.svg)
    bottom: 0
    right: 0
    z-index: 20
    @media screen and (max-width: 1000px)
      display: none
  &__buildings
    &.for-visitors
      .start-ny__button--2
        left: vw(36px)
        top: vw(-158px)
        @media screen and (max-width: 1000px)
          top: vwm(-122px)
          left: vwm(-9px)
      .start-ny__button--3
        left: vw(-5px)
        top: vw(-169px)
        @media screen and (max-width: 1000px)
          left: vwm(8px)
          top: vwm(-124px)
      .start-ny__button--1
        left: vw(119px)
        top: vw(-138px)
        @media screen and (max-width: 1000px)
          top: vwm(-119px)
          left: vwm(-19px)
      .start-ny__button--9
        left: vw(24px)
        top: vw(-175px)
        @media screen and (max-width: 1000px)
          left: vwm(13px)
          top: vwm(-107px)
      .start__building-7
        @media screen and (max-width: 1000px)
          width: vwm(97px)
          height: vwm(213px)
          background-image: url(../assets/img/building-7-mob-visitors.svg)
          left: vwm(19px)
          bottom: 0
          z-index: 70
      .start__building-5
        @media screen and (max-width: 1000px)
          width: vwm(176px)
          height: vwm(152px)
          background-image: url(../assets/img/building-6-mob.svg)
          left: auto
          right: vwm(28px)
          z-index: 70
      .start__building-3
        @media screen and (max-width: 1000px)
          width: vwm(89px)
          height: vwm(263px)
          background-image: url(../assets/img/building-2-mob.svg)
          left: auto
          right: vwm(0px)
      .start__building-2
        @media screen and (max-width: 1000px)
          width: vwm(89px)
          height: vwm(498px)
          background-image: url(../assets/img/building-7-mob.svg)
          left: vwm(24px)
          right: auto
          bottom: vwm(83px)
      .start__building-4
        @media screen and (max-width: 1000px)
          width: vwm(100px)
          height: vwm(356px)
          background-image: url(../assets/img/building-4-mob-visitors.svg)
          left: vwm(141px)
          z-index: 10
      .start__building-1
        @media screen and (max-width: 1000px)
          width: vwm(131px)
          height: vwm(571px)
          background-image: url(../assets/img/building-1-mob-visitors.svg)
          right: vwm(6px)
      .start__building-8
        @media screen and (max-width: 1000px)
          width: vwm(100px)
          height: vwm(109px)
          background-image: url(../assets/img/building-8-mob-visitors.svg)
          right: vwm(133px)
      .start__building-6
        @media screen and (max-width: 1000px)
          display: none
  &__building
    position: absolute
    background-size: contain
    background-repeat: no-repeat
    &-1
      width: vw(112px)
      height: vw(561px)
      background-image: url(../assets/img/building-1.svg)
      bottom: 0
      left: vw(51px)
      z-index: 10
      @media screen and (max-width: 1000px)
        width: vwm(108px)
        height: vwm(471px)
        background-image: url(../assets/img/building-1-mob.svg)
        bottom: vwm(0px)
        left: auto
        right: vwm(9px)
    &-2
      width: vw(130px)
      height: vw(374px)
      background-image: url(../assets/img/building-2.svg)
      bottom: 0
      left: vw(186px)
      z-index: 10
      @media screen and (max-width: 1000px)
        width: vwm(89px)
        height: vwm(263px)
        background-image: url(../assets/img/building-2-mob.svg)
        bottom: vwm(0px)
        left: auto
        right: vwm(4px)
        z-index: 40
    &-3
      width: vw(168px)
      height: vw(439px)
      background-image: url(../assets/img/building-3.svg)
      bottom: 0
      left: vw(331px)
      z-index: 40
      @media screen and (max-width: 1000px)
        width: vwm(100px)
        height: vwm(376px)
        background-image: url(../assets/img/building-3-mob.svg)
        bottom: vwm(0px)
        left: vwm(141px)
    &-4
      width: vw(75px)
      height: vw(310px)
      background-image: url(../assets/img/building-4.svg)
      bottom: 0
      left: vw(522px)
      z-index: 10
      @media screen and (max-width: 1000px)
        width: vwm(100px)
        height: vwm(89px)
        background-image: url(../assets/img/building-4-mob.svg)
        bottom: vwm(0px)
        left: vwm(125px)
        z-index: 80
    &-5
      width: vw(249px)
      height: vw(360px)
      background-image: url(../assets/img/building-5.svg)
      bottom: 0
      left: vw(-19px)
      right: 0
      margin: auto
      z-index: 40
      @media screen and (max-width: 1000px)
        width: vwm(97px)
        height: vwm(163px)
        background-image: url(../assets/img/building-5-mob.svg)
        bottom: vwm(0px)
        left: vwm(19px)
        right: auto
        z-index: 50
    &-6
      width: vw(108px)
      height: vw(355px)
      background-image: url(../assets/img/building-6.svg)
      bottom: 0
      right: vw(512px)
      z-index: 10
      @media screen and (max-width: 1000px)
        width: vwm(176px)
        height: vwm(152px)
        background-image: url(../assets/img/building-6-mob.svg)
        bottom: vwm(0px)
        right: vwm(22px)
        z-index: 80
    &-7
      width: vw(156px)
      height: vw(439px)
      background-image: url(../assets/img/building-7.svg)
      bottom: 0
      right: vw(323px)
      z-index: 40
      @media screen and (max-width: 1000px)
        width: vwm(89px)
        height: vwm(498px)
        background-image: url(../assets/img/building-7-mob.svg)
        bottom: vwm(3px)
        right: auto
        left: vwm(24px)
    &-8
      width: vw(148px)
      height: vw(321px)
      background-image: url(../assets/img/building-8.svg)
      bottom: 0
      right: vw(170px)
      z-index: 10
      @media screen and (max-width: 1000px)
        width: vwm(64px)
        height: vwm(310px)
        background-image: url(../assets/img/building-8-mob.svg)
        bottom: vwm(0px)
        right: vwm(122px)
        z-index: 70
    &-9
      width: vw(90px)
      height: vw(356px)
      background-image: url(../assets/img/building-9.svg)
      bottom: 0
      right: vw(63px)
      z-index: 10
      @media screen and (max-width: 1000px)
        width: vwm(81px)
        height: vwm(279px)
        background-image: url(../assets/img/building-9-mob.svg)
        bottom: vwm(0px)
        right: auto
        left: vwm(89px)
        z-index: 40
  &__logo
    width: vw(263px)
    right: vw(28px)
    top: vw(40px)
    position: absolute
    @media screen and (max-width: 1000px)
      display: none
    img
      width: 100%
  // &__element
  //   background-size: contain
  //   background-repeat: no-repeat
  //   position: absolute
  //   &-1
  //     background-image: url(../assets/img/lock.svg)
  //     width: vw(79px)
  //     height: vw(124px)
  //     top: vw(166px)
  //     left: vw(96px)
  &__group
    width: 100%
    height: 100%
    background-size: 100%
    background-position: 50% 100%
    background-repeat: no-repeat
    background-image: url(../assets/img/group.png)
    position: relative
    @media screen and (max-width: 1000px)
      width: 100%
      height: vwm(297px)
      background-image: url(../assets/img/group-mob.png)
      background-position: 0 0
      position: absolute
      left: 0
      right: 0
      margin: auto
      bottom: vwm(206px)
    &-element
      position: absolute
      // background-size: contain
      // background-repeat: no-repeat
      // background-image: url(../assets/img/lights.svg)
      width: vw(161px)
      height: vw(101px)
      right: 0
      left: 0
      margin: auto
      @media screen and (max-width: 1000px)
        width: vwm(90px)
        height: vwm(67px)
      &-1
        left: vw(-53px)
        bottom: vw(270px)
        @media screen and (max-width: 1000px)
          left: vwm(-3px)
          bottom: vwm(108px)
        &:after
          animation: sparkletop 3s linear 0s infinite
          position: absolute
          width: 100%
          height: 100%
          background-size: contain
          background-repeat: no-repeat
          content: ''
          display: block
      &-2
        transform: rotate(180deg)
        left: vw(-42px)
        bottom: vw(74px)
        @media screen and (max-width: 1000px)
          left: vwm(-2px)
          bottom: vwm(27px)
        &:after
          animation: sparklebottom 3s linear 0s infinite
          position: absolute
          width: 100%
          height: 100%
          background-size: contain
          background-repeat: no-repeat
          content: ''
          display: block
  &__line
    width: vw(1380px)
    height: 91.6vh
    border: 1px solid #FF4F12
    border-radius: 2px
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    margin: auto
    z-index: 9
    @media screen and (max-width: 1000px)
      width: 94%
      height: 97%
  &__box
    width: vw(1380px)
    height: 91.6vh
    border: 1px solid #FF4F12
    border-radius: 2px
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    margin: auto
    z-index: 100000
    display: flex
    justify-content: center
    align-items: center
    @media screen and (max-width: 1000px)
      width: 94%
      height: 97%
    &-block
      margin-top: vw(-9px)
      @media screen and (max-width: 1000px)
        margin-top: vwm(13px)
    &-title
      width: vw(624px)
      height: vw(109px)
      border-radius: vw(23px)
      background-color: #fff
      display: flex
      align-items: center
      justify-content: center
      margin: auto
      margin-bottom: vw(26px)
      @media screen and (max-width: 1000px)
        width: vwm(288px)
        height: vwm(128px)
        border-radius: vwm(23px)
        margin-bottom: vwm(21px)
      span
        background-color: #7700FF
        width: vw(576px)
        height: vw(90px)
        border-radius: vw(18px)
        color: #fff
        font-size: vw(36px)
        font-weight: 500
        text-align: center
        box-shadow: 0px vw(8.73px) vw(22.11px) 0px rgba(11, 0, 52, 0.5)
        @media screen and (max-width: 1000px)
          width: vwm(273px)
          height: vwm(116px)
          border-radius: vwm(18px)
          font-size: vwm(29px)
          line-height: vwm(34px)
          padding-top: vwm(9px)
    &-text
      font-size: vw(16px)
      line-height: vw(21px)
      font-weight: 500
      color: #fff
      text-align: center
      margin-bottom: vw(30px)
      @media screen and (max-width: 1000px)
        font-size: vwm(16px)
        line-height: vwm(21px)
        margin-bottom: vwm(24px)
      span
        color: #FF4F12
      br
        &.mob-only
          display: none
          @media screen and (max-width: 1000px)
            display: block
        @media screen and (max-width: 1000px)
          display: none
    &-items
      display: flex
      justify-content: center
      gap: vw(27px)
      flex-wrap: wrap
      @media screen and (max-width: 1000px)
        gap: 0
    &-item
      cursor: pointer
      @media screen and (max-width: 1000px)
        width: 38%
        margin-bottom: vwm(22px)
      &-icon
        width: vw(77px)
        height: vw(77px)
        border-radius: 50%
        background-color: #fff
        border: vw(4px) solid #FF4F12
        margin: auto
        margin-bottom: vw(13px)
        background-repeat: no-repeat
        @media screen and (max-width: 1000px)
          width: vwm(66px)
          height: vwm(66px)
          border-width: vwm(4px)
          margin-bottom: vwm(12px)
        &--1
          background-image: url(../assets/img/icon-1.svg)
          background-size: vw(74px)
          @media screen and (max-width: 1000px)
            background-size: vwm(63px)
        &--2
          background-image: url(../assets/img/icon-2.svg)
          background-size: vw(43px)
          background-position: 50% 42%
          @media screen and (max-width: 1000px)
            background-size: vwm(38px)
        &--3
          background-image: url(../assets/img/icon-3.svg)
          background-size: vw(67px)
          background-position: 50% 75%
          @media screen and (max-width: 1000px)
            background-size: vwm(58px)
            background-position: 50% vwm(-1px)
        &--4
          background-image: url(../assets/img/icon-4.svg)
          background-size: vw(69px)
          background-position: 50% 100%
          @media screen and (max-width: 1000px)
            background-size: vwm(60px)
      &-title
        width: vw(124px)
        min-width: vw(124px)
        background-color: #FF4F12
        border-radius: vw(7px)
        color: #fff
        text-transform: uppercase
        text-align: center
        font-size: vw(14px)
        line-height: vw(19px)
        font-family: 'Tektur'
        font-weight: 600
        padding: vw(2px) vw(10px)
        letter-spacing: 0.4px
        margin: auto
        @media screen and (max-width: 1000px)
          width: vwm(108px)
          min-width: vwm(108px)
          border-radius: vwm(7px)
          font-size: vwm(13px)
          line-height: vwm(18px)
          padding: vwm(3px) vwm(10px)
  &__overlay
    width: 100%
    height: 100%
    background: linear-gradient(132.31deg, #212120 -10.21%, rgba(115, 115, 115, 0) 73.83%)
    position: absolute
    top: 0
    left: 0
    bottom: 0
    margin: auto
    @media screen and (max-width: 1000px)
      height: vwm(285px)
      display: none
  &-bg
    width: 100vw
    height: 100vh
    height: var(--app-height)
    background-size: cover
    background-repeat: no-repeat
    background-position: 50% 50%
    position: absolute
    top: 0
    bottom: 0
    margin: auto
    @media screen and (max-width: 1000px)
      height: vwm(285px)
      position: fixed
    &-1
      background-image: url(../assets/img/start-bg-1.jpg)
      left: 0
      .start__year
        right: vw(-59px)
      @media screen and (max-width: 1000px)
        background-size: vwm(500px)
    &-2
      background-image: url(../assets/img/start-bg-2.jpg)
      left: 50%
      background-attachment: fixed
      @media screen and (max-width: 1000px)
        background-size: vwm(500px)
      .start__year
        position: fixed
        right: vw(-65px)
        @media screen and (max-width: 1000px)
          top: 50vw
          bottom: 0
          margin: auto
          right: -4vw
          height: 11vw
  &__title
    font-size: vw(114px)
    line-height: vw(105px)
    font-weight: 700
    color: #fff
    text-transform: uppercase
    letter-spacing: vw(11.4px)
    position: absolute
    top: vw(58px)
    left: vw(53px)
    z-index: 100
    width: vw(580px)
    height: vw(188px)
    background-image: url(../assets/img/start-screen-logo.png)
    background-size: contain
    background-repeat: no-repeat
    @media screen and (max-width: 1000px)
      top: 0
      bottom: 37vw
      margin: auto
    span
      &.title-2
        margin-left: vw(144px)
  &__year
    font-size: vw(123px)
    font-weight: 700
    color: #FF4F12
    letter-spacing: vw(6px)
    position: absolute
    //right: vw(-59px)
    bottom: vw(109px)
    transform: rotate(-90deg)
  &__button
    position: absolute
    bottom: vw(73px)
    left: 0
    right: 0
    margin: auto
    text-align: center
    z-index: 100001
    @media screen and (max-width: 1000px)
      bottom: 0
      top: 130vw
      margin: auto
      height: 14.3vw
    &-link
      background-color: #FF4F12
      color: #fff
      font-size: vw(16px)
      text-transform: uppercase
      font-weight: 500
      display: inline-block
      height: vw(54px)
      line-height: vw(54px)
      // border: 1px solid #7700FF
      padding: 0 vw(67px)
      letter-spacing: vw(1.6px)
      // box-shadow: vw(4px) vw(4px) 0px 0px rgba(119, 0, 255, 1)
      @media screen and (max-width: 1000px)
        font-size: vwm(16px)
        height: vwm(54px)
        line-height: vwm(54px)
        padding: 0 vwm(67px)
        letter-spacing: vwm(1.6px)
        // box-shadow: vwm(4px) vwm(4px) 0px 0px rgba(119, 0, 255, 1)
.start-ny__button
  opacity: 0
  transition: all 300ms
  transform: translateY(-20px)
  &.visible
    opacity: 1
    transform: translateY(0)
</style>

<style>
@keyframes sparkletop {
  0% {
    background-image: url("../assets/img/bright.png");
  }
  10% {
    background-image: url("../assets/img/bright-2.png");
  }
  20% {
    background-image: url("../assets/img/bright-3.png");
  }
  30% {
    background-image: url("../assets/img/bright-2.png");
  }
  40% {
    background-image: url("../assets/img/bright.png");
  }
  50% {
    background-image: url("../assets/img/bright-3.png");
  }
  60% {
    background-image: url("../assets/img/bright-2.png");
  }
  70% {
    background-image: url("../assets/img/bright.png");
  }
  80% {
    background-image: url("../assets/img/bright-3.png");
  }
  90% {
    background-image: url("../assets/img/bright-2.png");
  }
  100% {
    background-image: url("../assets/img/bright.png");
  }
}
@keyframes sparklebottom {
  0% {
    background-image: url("../assets/img/bright.png");
  }
  10% {
    background-image: url("../assets/img/bright-2.png");
  }
  20% {
    background-image: url("../assets/img/bright-3.png");
  }
  30% {
    background-image: url("../assets/img/bright-2.png");
  }
  40% {
    background-image: url("../assets/img/bright.png");
  }
  50% {
    background-image: url("../assets/img/bright-3.png");
  }
  60% {
    background-image: url("../assets/img/bright-2.png");
  }
  70% {
    background-image: url("../assets/img/bright.png");
  }
  80% {
    background-image: url("../assets/img/bright-3.png");
  }
  90% {
    background-image: url("../assets/img/bright-2.png");
  }
  100% {
    background-image: url("../assets/img/bright.png");
  }
}
</style>
